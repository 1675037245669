import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppCfg } from '../../app.config';
import { catchError, map } from 'rxjs/operators';
import { ConsoleService } from '../console/console.service';
import { of } from 'rxjs';
import stringInject from 'stringinject';

@Injectable({
  providedIn: 'root'
})

export class TransportService {

  /**
   * TODO: use it later for cache purpose.
   */
  public structure: any = null;
  public results: any = null;

  constructor(
    private _http: HttpClient,
    private _console: ConsoleService,
  ) {

  }

  /**
   * Retrieve the form Structure from CLASS.definition
   */
  getStructure() {

    const endpoint = AppCfg.endpoint + AppCfg.attributesEndpoint.transportList.definition;
    const cacheDefinition = this.getCacheRequest(endpoint);

    if (cacheDefinition) {
      return of(cacheDefinition);
    }
    return this._http.get(endpoint).pipe(
      map((data: any) => {
        this.setCacheRequest(endpoint, data);
        return data;
      }),
    );

  }

  /**
   * Retriever all carers
   * @param searchParameters
   */
  getList(searchParameters) {
    return this._http.post(AppCfg.endpoint + AppCfg.attributesEndpoint.transportList.search, searchParameters).pipe(
      map((data: any) => data),
      catchError(() => of({})),
    );
  }

  /**
   * Sends an SMS to the carer family.
   *
   * @param params
   */
  sendSMS(params) {
    return this._http.post(AppCfg.endpoint + AppCfg.attributesEndpoint.transportList.sendSMS, params).pipe(
      map((data: any) => data),
      catchError(() => of({}))
    );
  }

  /**
   * Updates an item from transport list.
   *
   * @param idturnus
   * @param params
   */
  update(idturnus, params) {
    return this._http.put(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.transportList.update, {idturnus}), params);
  }

  /**
   * Retrieve the UID definition for the table
   * @param beanDefinition
   */
  getUID(beanDefinition) {
    return this._http.get('./assets/uid/' + beanDefinition + '.json').pipe(
      map((data: any) => data),
      catchError(() => of({}))
    );
  }

  /**
   * Retrieve the UID definition for the table
   * @param patientPartnerNummer
   */
  getHistory(patientPartnerNummer) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.transportList.history, {patientPartnerNummer})).pipe(
      map((data: any) => data),
      catchError(() => of({}))
    );
  }

  /**
   * Prepare the data to be sent oback to the server.
   * Create multi depth layer from objects with <table>.<relational>
   * Create single depth layer objects
   * @param data
   */
  prepare(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(data.controllers);
    });
  }


  getCacheRequest(endpoint) {
    return (localStorage.getItem(endpoint) ? JSON.parse(localStorage.getItem(endpoint)) : null);
  }

  setCacheRequest(endpoint, response) {
    return localStorage.setItem(endpoint, JSON.stringify(response));
  }


  /**
   * Gets the invoice supplier data.
   *
   * @param idturnus
   */
  getInvoiceSupplier(idturnus) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.invoice.supplier, {idturnus})).pipe(
      map((data: any) => data),
      catchError(() => of({}))
    );
  }

  /**
   * Gets the invoice customer data.
   *
   * @param idturnus
   */
  getInvoiceCustomer(idturnus) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.invoice.customer, {idturnus})).pipe(
      map((data: any) => data),
      catchError(() => of({}))
    );
  }

  /**
   * Gets the invoice customer input statement.
   *
   * @param idturnus
   */
  getInvoiceInputStatement(idturnus) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.invoice.inputStatement, {idturnus})).pipe(
      map((data: any) => data),
      catchError(() => of({}))
    );
  }

  /**
   * Gets the invoice turnus output statement.
   *
   * @param idturnus
   */
  getInvoiceOutputStatement(idturnus) {
    return this._http.get(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.invoice.outputStatement, {idturnus})).pipe(
      map((data: any) => data),
      catchError(() => of({}))
    );
  }

  /**
   * Gets the languages types.
   */
  getLanguagesTypes() {
    return this._http.get(AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.print.languagesTypes).pipe(
      map((data: any) => data),
      catchError(() => of({})),
    );
  }

  /**
   * Gets the patient bill PDF file.
   *
   * @param idjoposten
   */
  getPatientBillFile(idjoposten) {
    return this._http.post(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.print.patientBill, {idjoposten}), null, {
      responseType: 'blob'
    });
  }

  /**
   * Gets the patient datasheet PDF file.
   *
   * @param data
   */
  getPatientDatasheetFile(data) {
    return this._http.post(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.print.patientDatasheet), data, {
      responseType: 'blob'
    });
  }

  /**
   * Gets the contract PDF file.
   *
   * @param idpatientturnus
   * @param data
   */
  getContractFile(idpatientturnus, data) {
    return this._http.post(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.print.contract, {idpatientturnus}), data, {
      responseType: 'blob'
    });
  }

  /**
   * Gets the transport list PDF file.
   *
   * @param searchParameters
   */
  getTransportListFile(searchParameters) {
    return this._http.post(AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.print.transports, searchParameters, {
      responseType: 'blob'
    });
  }


  /**
   * Saves a svainstallmentpayment.
   *
   * @param params
   */
  saveSvaInstallmentPayment(params) {
    return this._http.post(AppCfg.endpoint + AppCfg.attributesEndpoint.pfleger.sva.saveSvaInstallmentPayment, params);
  }
  /**
   * Updates an svainstallmentpayment.
   *
   * @param idsvainstallmentpayments
   * @param params
   */
  updateSvaInstallmentPayment(idsvainstallmentpayments, params) {
    return this._http.put(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.sva.updateSvaInstallmentPayment, {idsvainstallmentpayments}), params);
  }
  /**
   * Deletes an svainstallmentpayment.
   *
   * @param idsvainstallmentpayments
   */
  deleteSvaInstallmentPayment(idsvainstallmentpayments) {
    return this._http.delete(AppCfg.endpoint + stringInject(AppCfg.attributesEndpoint.pfleger.sva.deleteSvaInstallmentPayment, {idsvainstallmentpayments}));
  }
}
